@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,500;0,900;1,200;1,500;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  min-height: 100vh;
}

h1,h2,h3,h4,h5,p {
  font-family: 'Montserrat', sans-serif;
}

a,a:visited,a:link {
  color: white;
}

hr {
  -webkit-filter: drop-shadow(0px 0px 2px #9c45ff);
          filter: drop-shadow(0px 0px 2px #9c45ff);
}

.social-stack {
  -webkit-animation: rotateText 1s forwards;
          animation: rotateText 1s forwards;
}

@media only screen and (min-width: 1024px) { /* Desktop */

  .hero-title {
    font-size: 5vw;
    line-height: 5vw;
  }
  
  .hero {
    height: 100vh;
    padding: 20%!important;
    text-align: left;
    align-items: start;
  }
  
  .section {
    height: 90vh;
    padding-left: 20%!important;
    padding-right: 20%!important;
    padding-top: 5%!important;
    padding-bottom: 5%!important;
  }

  .hero-subtitle {
    font-size: 1.5vw;
  }

  .hero-divider {
    width: 25%!important;
    margin-top: 25px!important;
    margin-bottom: 25px!important;
    -webkit-animation: dividerStretch 1s forwards;
            animation: dividerStretch 1s forwards;
  }

  .hero-scrolltext {
    font-size: 1vw;
  }

  .project-image {
    padding-left: 10%;
    margin-bottom: 0;
    width: 100%;
    height: auto;
    transition: width 1s ease-in-out;
  }

  .project-title {
    margin-top: min(-5vh, -5vw);
    font-size: max(8vh, 8vw); 
    line-height: 1;
    mix-blend-mode: exclusion;
  }

  #projects {
    padding: 0!important;
    max-width: 100%;
    text-align: center;
    -webkit-filter: drop-shadow(0 0 20px rgba(255,255,255, 0.5));
            filter: drop-shadow(0 0 20px rgba(255,255,255, 0.5));
    margin-bottom: 50%;
    overflow: hidden;
  }

  .project-right {
    position: absolute;
    z-index: 0;
    left: 65%;
    text-align: justify;
  }

  .project > p {
    width: 100%;
  }

  .project-left {
    z-index: 1;
    padding-right: 10%!important;
  }


  .social-stack {
    text-align: left;
  }
}

@media only screen and (orientation:portrait) {
  .hero {
    text-align: center;
    align-items: center;
  }
}

@media only screen and (orientation:landscape) {
  .hero {
    text-align: left;
    align-items: start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) { /* Desktop */

  .social-stack > a:not(:last-child) {
    margin-right: 10px;
  }
  .hero-title {
    font-size: 7vw;
    line-height: 5vw;
  }
  
  .hero {
    height: 100vh;
    padding: 10%!important;
  }
  
  .section {
    height: 90vh;
    padding-left: 20%!important;
    padding-right: 20%!important;
    padding-top: 5%!important;
    padding-bottom: 5%!important;
  }

  .hero-subtitle {
    font-size: 4vw;
  }

  .hero-divider {
    width: 25%!important;
    margin-top: 25px!important;
    margin-bottom: 25px!important;
    -webkit-animation: dividerStretch 1s forwards;
            animation: dividerStretch 1s forwards;
  }

  .hero-scrolltext {
    font-size: 1vw;
  }

  .project-image {
    padding-left: 10%;
    margin-bottom: 0;
    width: 100%;
    height: auto;
    transition: width 1s ease-in-out;
  }

  .project-title {
    margin-top: min(-5vh, -5vw);
    font-size: max(8vh, 8vw); 
    line-height: 1;
    mix-blend-mode: exclusion;
  }

  #projects {
    padding: 0!important;
    max-width: 100%;
    text-align: center;
    -webkit-filter: drop-shadow(0 0 20px rgba(255,255,255, 0.5));
            filter: drop-shadow(0 0 20px rgba(255,255,255, 0.5));
    margin-bottom: 50%;
    overflow: hidden;
  }

  .project-right {
    position: absolute;
    z-index: 0;
    left: 65%;
    text-align: justify;
  }

  .project > p {
    width: 100%;
  }

  .project-left {
    z-index: 1;
    padding-right: 10%!important;
  }


  .social-stack {
    text-align: left;
  }
  

}
@media only screen and (max-width: 767px) {
  .hero-title {
    font-size: 7vw;
    text-align: center!important;
  }

  .hero-subtitle {
    font-size: 5vw;
    text-align: center!important;
  }

  .social-stack {
    text-align: center;
  }

  .social-stack > a:not(:last-child) {
    margin-right: 10px;
  }
  
  .hero {
    height: 85vh;
    padding: 5%!important;
  }

  .hero-divider {
    width: 100%!important;
    margin-top: 15px!important;
    -webkit-animation: dividerStretch 0.5s forwards;
            animation: dividerStretch 0.5s forwards;
  }
}

.hero-subtitle {
  font-weight: 200;
  margin: 0;
  z-index: 1;
  -webkit-animation: rotateText 1s ease-in-out;
          animation: rotateText 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.hero-title {
  margin: 0;
  z-index: 1;
  -webkit-animation: rotateText 1s ease-in-out;
          animation: rotateText 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.nowplaying {
  margin: 0;
  padding-left: 10px;
  display: inline-block;
  font-size: 15px;
  line-height: 18px;
}

.spotify {
  -webkit-animation: fadeIn 1s forwards;
          animation: fadeIn 1s forwards;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes rotateText {
  0% {
    transform: rotateX(-90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes rotateText {
  0% {
    transform: rotateX(-90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@-webkit-keyframes dividerStretch {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);    
  }
}

@keyframes dividerStretch {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);    
  }
}

.spotify {
  z-index: 1;
}

.hero-scrolltext {
  font-weight: 200;
  display: inline-block;
  z-index: 1;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
  opacity: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 20px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.background {
  content: '';
  background-image: url(/static/media/background.bdc6a63f.svg);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.content {
  z-index: 1;
}

#code-blur {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  z-index: 0;
}

#code {
  top: 0;
  left: 0;
  position: relative;
  z-index: 1;
}

#code-section {
  position: relative;
}

.text-uppercase {
  text-transform: uppercase;
}

.split-parent {
  overflow: hidden;
}
.split-child {
  display: inline-block;
}

::selection {
  background-color: #9c45ff;
  color: white;
}

::-moz-selection {
  background-color: #9c45ff;
  color: white;
}
